import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Utils from '../../../../../utils';

import FestivalFooterCopyright from './FestivalFooterCopyright/FestivalFooterCopyright';
import FestivalFooterNavigation from './FestivalFooterNavigation/FestivalFooterNavigation';
import FestivalFooterSponsors from './FestivalFooterSponsors/FestivalFooterSponsors';
import FestivalFooterSocialNetwork from './FestivalFooterSocialNetwork/FestivalFooterSocialNetwork';
import CallToAction from '../../../../shared/CallToAction/CallToAction';

import './FestivalFooter.scss';

export const contentConfig = {
  hashTag: '#ATxSG',
  eventName: 'Asia Tech x Singapore',
  socialNetworkConfig: {
    menuConfig: [
      {
        path: 'https://www.facebook.com/AsiaTechxSG/',
        faIcon: 'fab fa-facebook-square fa-2x',
      },
      {
        path: 'https://www.linkedin.com/company/atxsg/',
        faIcon: 'fab fa-linkedin fa-2x',
      },
      {
        path: 'https://twitter.com/AsiaTechxSG/',
        faIcon: 'fab fa-square-x-twitter fa-2x',
      },
    ],
  },
  eventDates: {
    startDate: '2022-05-31',
    endDate: '2022-06-03',
    adminPanel: {
      minDate: '2022-05-31',
      maxDate: '2022-06-03',
      enable: [
        {
          from: '2022-05-31',
          to: '2022-06-03',
        },
      ],
    },
  },
  footerSection: {
    showFormattedDate: false,
    formattedDateFormat: 'YYYY',
    customEventDatesText: '29 - 31 May 2024',
    showScrollToTop: false,
    registerInterestText: '',
    registerInterestUrl: '',
    sponsorsConfig: [
      {
        heading: 'Held in',
        listing: [
          {
            logo: 'SINGAPORE',
            href: 'https://www.visitsingapore.com/',
            target: '_blank',
          },
        ],
      },
      {
        heading: 'Brought to you by',
        listing: [
          {
            logo: 'IMDA',
            href: 'https://www.imda.gov.sg/',
            target: '_blank',
          },
          {
            logo: 'INFORMA_TECH',
            href: 'https://www.informatech.com/',
            target: '_blank',
          },
        ],
      },
      {
        heading: 'Supported by',
        listing: [
          {
            logo: 'SECB',
            href: 'https://www.visitsingapore.com/mice/en/about-us/about-secb/',
            target: '_blank',
          },
        ],
      },
    ],
    menuConfig: [
      {
        path: 'https://www.informa.com/generic-content/cookie-policy/#:~:text=When%20you%20first%20visit%20the,Cookies%20at%20any%20later%20time',
        label: 'Cookies',
      },
      {
        path: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/942f64ed-e8f8-440a-b4b8-3f28c9c648f8.html',
        label: 'CPPA: Do not sell my personal information',
      },
      {
        path: 'https://www.informa.com/privacy-policy/',
        label: 'Privacy',
      },
      {
        path: 'https://asiatechxsg.com/terms-and-conditions/',
        label: 'Terms',
      },
      {
        path: 'https://asiatechxsg.com/media-hub/',
        label: 'Press & Media',
      },
      {
        path: 'mailto:info@asiatechxsg.com?subject=ATX%20Contact%20Us%20Enquiry',
        label: 'Contact Us',
        target: '_blank',
      },
    ],
  },
};

export function FestivalFooter(props) {
  const {
    tenantConfig: { copyright },
  } = props;

  const {
    hashTag,
    eventDates: { startDate },
    socialNetworkConfig,
    footerSection: {
      showFormattedDate,
      formattedDateFormat = 'MMM YYYY',
      customEventDatesText,
      showScrollToTop,
      registerInterestUrl,
      registerInterestText,
      menuConfig = [],
      sponsorsConfig = [],
    },
  } = contentConfig;

  return (
    <footer className="c-festival-footer">
      <div className="container">
        <div className="row">
          <div className="col-xs">
            <div className="c-festival-footer__top">
              {(showFormattedDate || hashTag) && (
                <div className="c-festival-footer__festival-info">
                  {customEventDatesText && (
                    <div className="c-festival-footer__custom-dates-text">
                      {customEventDatesText}
                    </div>
                  )}
                  {showFormattedDate && (
                    <div className="c-festival-footer__short-date">
                      {Utils.formattedTime(startDate, formattedDateFormat)}
                    </div>
                  )}
                  {hashTag && (
                    <div className="c-festival-footer__hash-tag">{hashTag}</div>
                  )}
                </div>
              )}
              {sponsorsConfig.length > 0 && (
                <div className="c-festival-footer__sponsors">
                  <FestivalFooterSponsors config={sponsorsConfig} />
                </div>
              )}
            </div>
          </div>
        </div>
        {(showFormattedDate || hashTag) && <hr />}
        <div className="c-festival-footer__bottom row">
          <div className="col-xs">
            <div className="c-festival-footer__social-network">
              <FestivalFooterSocialNetwork config={socialNetworkConfig} />
            </div>
            {registerInterestUrl && registerInterestText && (
              <div className="c-festival-footer__register-interest">
                <CallToAction
                  to={registerInterestUrl}
                  label={registerInterestText}
                  rel="noopener noreferrer"
                />
              </div>
            )}
          </div>
          <div className="col-xs">
            <div className="c-festival-footer__navigation">
              <FestivalFooterNavigation
                menuConfig={menuConfig}
                showScrollToTop={showScrollToTop}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-xs">
            <div className="c-festival-footer__copyright">
              <FestivalFooterCopyright copyright={copyright} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

FestivalFooter.propTypes = {
  tenantConfig: PropTypes.shape({
    contentConfig: PropTypes.shape({
      footerSection: PropTypes.shape({
        copyright: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};

function mapStateToProps(state) {
  return {
    tenantConfig: state.pageConfig.tenantConfig,
  };
}

export default connect(mapStateToProps)(FestivalFooter);
